import { defineComponent, reactive, toRefs } from "vue";
import { getByUrl } from "@/utils/http";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

interface Data {
  newsList: Array<{
    id: number;
    imgDetails: null;
    imgPath: string;
    imgSource: null;
    imgSubtitle: string;
    imgTime: null;
    imgTitle: string;
    isHomePage: null;
    newsId: null;
  }>;
  jumpPage: (name: string, newsId: number) => void;
}

export default defineComponent({
  name: "NewsInformation",
  setup() {
    const router = useRouter();

    const data: Data = reactive({
      newsList: [],
      jumpPage: (name, newsId) => {
        router.push({ name, params: { newsId } });
      },
      formattingDate(date: string) {
        return dayjs(date, "YYYY-MM-DD").format("YYYY年MM月DD日");
      }
    });
    getByUrl("/news/index").then(res => {
      data.newsList = res;
    });

    return {
      ...toRefs(data)
    };
  }
});
