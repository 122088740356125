<template>
  <div class="news-information" v-if="newsList.length">
    <div class="hot-news" @click="jumpPage('NewsDetails', newsList[0].id)">
      <div>
        <img :src="newsList[0].imgPath" :alt="newsList[0].imgTitle" />
      </div>
      <div>
        <h1 class="ellipsis">
          {{ newsList[0].imgTitle }}
        </h1>
        <div>
          <p class="ellipsis-3">{{ newsList[0].imgSubtitle }}</p>
        </div>
        <div class="date">{{ formattingDate(newsList[0].imgTime) }}</div>
      </div>
    </div>
    <div class="news-list" v-if="newsList.length > 1">
      <template v-for="(item, index) in newsList" :key="`news-${item.id}`">
        <div v-if="index > 0" @click="jumpPage('NewsDetails', item.id)">
          <div class="news-figure">
            <img :src="item.imgPath" :alt="item.imgTitle" />
          </div>
          <div class="news-content">
            <h2 class="ellipsis">
              {{ item.imgTitle }}
            </h2>
            <div class="ellipsis-3">
              {{ item.imgSubtitle }}
            </div>
            <div>
              <div class="date">{{ item.imgTime }}</div>
              <div class="learn-more">了解更多></div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="scss" scoped>
.news-information {
  width: $effectiveArea;
  margin: 0 auto;
  .hot-news {
    display: flex;
    margin-top: 22px;
    &:hover {
      img {
        width: 110%;
        height: 110%;
        left: -5%;
        top: -5%;
      }
    }
    > div {
      cursor: pointer;

      &:first-child {
        width: 616px;
        height: 308px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 25px;
        position: relative;
      }
      &:last-child {
        width: 680px;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition-duration: 0.5s;
    }
    h1 {
      font-size: 36px;
      font-weight: bold;
      margin-top: 58px;
      margin-bottom: 35px;
    }
    p {
      font-size: 16px;
      font-weight: bold;
      text-indent: 2em;
    }
    .date {
      font-size: 14px;
      color: #666666;
      margin-top: 45px;
    }
  }
  .news-list {
    padding-top: 30px;
    padding-bottom: 30px;
    > div {
      display: inline-block;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #e5e5e5;
      transition-duration: 0.5s;
      width: 410px;
      height: 551px;
      cursor: pointer;
      margin-bottom: 27px;
      & + div {
        &:not(:nth-child(3n-2)) {
          margin-left: 27px;
        }
      }
      &:hover {
        box-shadow: 0px 3px 28px 1px rgba(5, 48, 88, 0.13);
        .news-figure {
          img {
            width: 110%;
            height: 110%;
            left: -5%;
            top: -5%;
          }
        }
      }
    }
    .news-figure {
      position: relative;
      width: 410px;
      height: 325px;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition-duration: 0.5s;
      }
    }
    .news-content {
      padding: 30px;
      h2 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
      }
      > div {
        &:nth-child(2) {
          min-height: 66px;
          max-height: 66px;
          margin: 20px 0;
          font-size: 14px;
        }
        &:last-child {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          align-items: center;
        }
        .date {
          color: #999999;
        }
        .learn-more {
          color: #008fe0;
        }
      }
    }
  }
}
</style>
